import { DecorateNameTags } from "@/Utils/Shared/DecorateNameTags.ts";

export const Department = {
  MANAGEMENT: 1,
  BUSINESS_DEVELOPMENT: 2,
  CUSTOMER_RELATIONS: 3,
  ACCOUNTS: 4,
  LOGISTICS: 5,
  OPERATIONS: 6,
  FEEDBACK: 7,
  SERVICE: 8,
};
export const TaskStatus = {
  CANCEL: 0,
  TODO: 1,
  BLOCKED: 2,
  PROGRESS: 3,
  REVIEW: 4,
  DONE: 5,
};
export const departmentArray = Object.keys(Department).map((key) => ({
  label: DecorateNameTags(key),
  value: Department[key],
}));

// change
export const statusArray = Object.keys(TaskStatus).map((key) => ({
  label: key,
  value: TaskStatus[key],
}));

// change

export const excludeStatus = Object.keys(TaskStatus).map((key) => ({
  label: key,
  value: TaskStatus[key],
}));

export const SubtaskRadioButtons = [
  {
    no: 1,
    id: "Highest",
    name: "priority",
    label: "Highest",
    value: 1,
  },
  {
    no: 2,
    id: "High",
    name: "priority",
    label: "High",
    value: 2,
  },
  {
    no: 3,
    id: "Medium",
    name: "priority",
    label: "Medium",
    value: 3,
  },
  {
    no: 1,
    id: "Low",
    name: "priority",
    label: "Low",
    value: 4,
  },
];
export const CRStage = {
  CANCEL: "CANCEL",
  CREATED: "CREATED",
  BLOCKED: "BLOCKED",
  PROGRESS: "PROGRESS",
  REVIEW: "REVIEW",
  DONE: "DONE",
};
export const CRStageColor = {
  CANCEL: "danger",
  CREATED: "primary",
  BLOCKED: "danger",
  PROGRESS: "warning",
  REVIEW: "info",
  DONE: "success",
};
export const CRStageIcon = {
  CANCEL: "bi bi-x-circle-fill",
  CREATED: "fa fa-brands fa-first-order",
  BLOCKED: "fa fa-hand-paper",
  PROGRESS: "bi bi-clock-history",
  REVIEW: "fa fa-hand-paper",
  DONE: "fa fa-handshake",
};

export const SUPPORT_REQUEST_CATEGORY = {
  RENTAL: 1,
  SALES: 2,
  PERSONAL: 3,
};

export const SRCategoryColor = {
  RENTAL: "primary",
  SALES: "success",
  PERSONAL: "danger",
};
